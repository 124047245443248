/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import deliveryProcessTypes from './deliveryProcess.types';

/* ORDERED USER LIST START */
export const orderUserListStart = (listDetails) => ({
	type: deliveryProcessTypes.GET_ORDERED_USER_LIST_START,
	payload: listDetails,
});

export const orderUserListSuccess = (listSuccess) => ({
	type: deliveryProcessTypes.GET_ORDERED_USER_LIST_SUCCESS,
	payload: listSuccess,
});

export const orderUserListFailure = (error) => ({
	type: deliveryProcessTypes.GET_ORDERED_USER_LIST_FAILURE,
	payload: error,
});
/* ORDERED USER LIST END */

/* ORDERS PER USER LIST START */
export const perUserOrderListStart = (orderDetails) => ({
	type: deliveryProcessTypes.GET_PER_USER_ORDERS_LIST_START,
	payload: orderDetails,
});

export const perUserOrderSuccess = (orderListSuccess) => ({
	type: deliveryProcessTypes.GET_PER_USER_ORDERS_LIST_SUCCESS,
	payload: orderListSuccess,
});

export const perUserOrderFailure = (error) => ({
	type: deliveryProcessTypes.GET_PER_USER_ORDERS_LIST_FAILURE,
	payload: error,
});
/* ORDERS PER USER LIST END */

/* COMBO PRODUCTS LIST START */
export const comboProductListStart = (listDetails) => ({
	type: deliveryProcessTypes.GET_COMBO_PRODUCT_LIST_START,
	payload: listDetails,
});

export const comboProductListSuccess = (listSuccess) => ({
	type: deliveryProcessTypes.GET_COMBO_PRODUCT_LIST_SUCCESS,
	payload: listSuccess,
});

export const comboProductListFailure = (error) => ({
	type: deliveryProcessTypes.GET_COMBO_PRODUCT_LIST_FAILURE,
	payload: error,
});
/* COMBO PRODUCTS LIST END */
