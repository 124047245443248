/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import deliveryProcessTypes from './deliveryProcess.types';

const INITIAL_STATE = {
	orderedUserListResponse: null,
	userOrdersListResponse: null,
	comboProductsListResponse: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case deliveryProcessTypes.GET_ORDERED_USER_LIST_SUCCESS:
			return { ...state, orderedUserListResponse: action.payload };

		case deliveryProcessTypes.GET_ORDERED_USER_LIST_FAILURE:
			return { ...state, orderedUserListResponse: null };

		case deliveryProcessTypes.GET_PER_USER_ORDERS_LIST_SUCCESS:
			return { ...state, userOrdersListResponse: action.payload };

		case deliveryProcessTypes.GET_PER_USER_ORDERS_LIST_FAILURE:
			return { ...state, userOrdersListResponse: null };

		case deliveryProcessTypes.GET_COMBO_PRODUCT_LIST_SUCCESS:
			return { ...state, comboProductsListResponse: action.payload };

		case deliveryProcessTypes.GET_COMBO_PRODUCT_LIST_FAILURE:
			return { ...state, comboProductsListResponse: null };

		default:
			return state;
	}
};

export default userReducer;
