const deliveryProcessTypes = {
	GET_ORDERED_USER_LIST_START: 'GET_ORDERED_USER_LIST_START',
	GET_ORDERED_USER_LIST_SUCCESS: 'GET_ORDERED_USER_LIST_SUCCESS',
	GET_ORDERED_USER_LIST_FAILURE: 'GET_ORDERED_USER_LIST_FAILURE',

	GET_PER_USER_ORDERS_LIST_START: 'GET_PER_USER_ORDERS_LIST_START',
	GET_PER_USER_ORDERS_LIST_SUCCESS: 'GET_PER_USER_ORDERS_LIST_SUCCESS',
	GET_PER_USER_ORDERS_LIST_FAILURE: 'GET_PER_USER_ORDERS_LIST_FAILURE',

	GET_COMBO_PRODUCT_LIST_START: 'GET_COMBO_PRODUCT_LIST_START',
	GET_COMBO_PRODUCT_LIST_SUCCESS: 'GET_COMBO_PRODUCT_LIST_SUCCESS',
	GET_COMBO_PRODUCT_LIST_FAILURE: 'GET_COMBO_PRODUCT_LIST_FAILURE',
};
export default deliveryProcessTypes;
