/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import deliveryProcessTypes from './deliveryProcess.types';
import {
	getDeliveryOrderList,
	getUserOrderList,
	getComboProductOrderList,
} from './deliveryProcess.service';
import {
	orderUserListSuccess,
	orderUserListFailure,
	perUserOrderSuccess,
	perUserOrderFailure,
	comboProductListSuccess,
	comboProductListFailure,
} from './deliveryProcess.action';

/* ORDERED USER LIST START */
export function* deliveryProductList({ payload: { orderList } }) {
	try {
		const responseData = yield call(getDeliveryOrderList, orderList);
		if (responseData.data.statusCode === '100') {
			yield put(orderUserListFailure(responseData.data));
		} else {
			yield put(orderUserListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(orderUserListFailure(error));
	}
}

export function* getOrderDeliveryList() {
	yield takeLatest(deliveryProcessTypes.GET_ORDERED_USER_LIST_START, deliveryProductList);
}
/* ORDERED USER LIST END */

/* ORDERS PER USER LIST START */
export function* deliveryUserProductList({ payload: { userList } }) {
	try {
		const responseData = yield call(getUserOrderList, userList);
		if (responseData.data.statusCode === '100') {
			yield put(perUserOrderFailure(responseData.data));
		} else {
			yield put(perUserOrderSuccess(responseData.data));
		}
	} catch (error) {
		yield put(perUserOrderFailure(error));
	}
}

export function* getUserOrderDeliveryList() {
	yield takeLatest(deliveryProcessTypes.GET_PER_USER_ORDERS_LIST_START, deliveryUserProductList);
}
/* ORDERS PER USER LIST END */

/* COMBO PRODUCTS LIST START */
export function* comboProductListGet({ payload: { comboList } }) {
	try {
		const responseData = yield call(getComboProductOrderList, comboList);
		if (responseData.data.statusCode === '100') {
			yield put(comboProductListFailure(responseData.data));
		} else {
			yield put(comboProductListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(comboProductListFailure(error));
	}
}

export function* getComboProductList() {
	yield takeLatest(deliveryProcessTypes.GET_COMBO_PRODUCT_LIST_START, comboProductListGet);
}
/* COMBO PRODUCTS LIST END */

export function* deliveryListSaga() {
	yield all([
		call(getOrderDeliveryList),
		call(getUserOrderDeliveryList),
		call(getComboProductList),
	]);
}
